/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import "./styles.css";
import { themeColors } from "../../../utils/constants/themeColors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteredApiData,
  Locale,
  setCurrentScreen,
  setSelectedBrand,
} from "../../../utils/redux";
import { screenNames } from "../../../utils/constants/screenNames";
import { useEffect } from "react";
import WarningIcon from "@mui/icons-material/Warning";
// import { getBrandData } from "../../../utils/helperFunctions/helperFunction";
import {
  Cart,
  FiltredApiDataInterface,
} from "../../../utils/redux/reducer/order-slice";
import { offerTypes } from "../../../utils/constants/constatns";

const BrandSelection = () => {
  const locale = useSelector(Locale);
  const isCartEmpty = false;
  const dispatch = useDispatch();
  const filtredData = useSelector(FilteredApiData);
  const cartData = useSelector(Cart);

  const calculateProgress = (brand: FiltredApiDataInterface) => {
    let maxVolume = 0;
    let selected = 0;
    if (brand.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
      maxVolume = brand.maxVolume;
    } else {
      for (let sku of brand.skuData) {
        maxVolume = maxVolume + sku.maxVolume;
      }
    }
    for (let cartItem of cartData) {
      if (cartItem.brandIdentifier === brand.brandIdentifier) {
        for (let skuOrderItem of cartItem.skuData) {
          selected = selected + skuOrderItem.requestedVolume;
        }
      }
    }
    return (selected / maxVolume) * 100;
  };

  const checkWarningIcon = (brand: FiltredApiDataInterface) => {
    const isOrderInCart = cartData.find(
      (cartItem) => cartItem.brandIdentifier === brand.brandIdentifier
    );
    if (isOrderInCart) {
      return (
        <WarningIcon
          tabIndex={-1}
          sx={{
            color: "warning.light",
            "&:focus": {
              outline: "none",
            },
          }}
          data-tooltip-id="custom-tooltip"
          data-tooltip-content={"Confirm changes on Checkout"}
          aria-hidden="true"
        />
      );
    }
  };

  const getTotalUnits = (brand: FiltredApiDataInterface) => {
    let selected = 0;
    for (let cartItem of cartData) {
      if (cartItem.brandIdentifier === brand.brandIdentifier) {
        for (let skuOrderItem of cartItem.skuData) {
          selected = selected + skuOrderItem.requestedVolume;
        }
      }
    }
    return selected;
  };

  useEffect(() => {
    dispatch(setSelectedBrand({}));
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await getBrandData();
  //   })();
  // }, []);

  return (
    <Box className="flex fdc fullHeight">
      <Box className="f1 auto-o" padding={"7px 20px"}>
        <Box className="flex ai-c jc-sb">
          <Box className="flex ai-c">
            <IconButton
              onClick={() => {
                dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
              }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: 14, color: "black" }} />
            </IconButton>
            <Typography
              fontSize={18}
              fontWeight={400}
              sx={{ color: themeColors.primaryType1 }}
            >
              {locale?.labels?.chooseBrands?.toUpperCase()}
            </Typography>
          </Box>
          <Box>
            {isCartEmpty ? (
              <IconButton>
                <ShoppingCartOutlinedIcon className="cart-icon" />
              </IconButton>
            ) : (
              <Box className="relative">
                <IconButton>
                  <ShoppingCartIcon className="cart-icon" />
                </IconButton>
                <Box
                  className="absolute t0 r0 font12 cart-quntity flex jc-c ai-c"
                  sx={{ background: themeColors.primaryType1 }}
                >
                  {cartData.length}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="font11 flex g15">
          <Box className="flex ai-c g5">
            <Typography
              fontSize={11}
              fontWeight={700}
              style={{ color: themeColors.primaryType1 }}
            >
              {locale?.labels?.startDate}:
            </Typography>
            <span>{filtredData.startDate}</span>
          </Box>
          <Box className="flex ai-c g5">
            <Typography
              fontSize={11}
              fontWeight={700}
              style={{ color: themeColors.primaryType1 }}
            >
              {locale?.labels?.endDate}:
            </Typography>
            <span>{filtredData.endDate}</span>
          </Box>
        </Box>
        {filtredData.offerData &&
          filtredData.offerData.length > 0 &&
          filtredData.offerData.map((brand, index: number) => {
            return (
              <Box className="progress-card-container mt10" key={index}>
                <Box textAlign={"right"} className="flex jc-sb">
                  <Typography fontSize={13} style={{ fontWeight: 500 }}>
                    {brand.brand}
                  </Typography>
                  {checkWarningIcon(brand)}
                </Box>
                <Grid container>
                  <Grid xs item key={index} className="relative">
                    <LinearProgress
                      variant="determinate"
                      value={calculateProgress(brand)}
                      sx={{
                        height: "20px",
                        backgroundColor: "secondary.main",
                        borderRadius: "5px",
                        "& .MuiLinearProgress-bar": {
                          transition: "none",
                          backgroundColor: "primary",
                          borderRadius: "5px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    width={"fit-content"}
                    item
                    className="flex jc-c"
                    height={"20px"}
                    alignItems={"center"}
                    gap={"5px"}
                    ml={"5px"}
                  >
                    <Typography width={"25px"} textAlign={"center"}>
                      {getTotalUnits(brand)}
                    </Typography>
                    <Box
                      className="plus-icon-brand-selection"
                      onClick={() => {
                        dispatch(setSelectedBrand(brand));
                        dispatch(setCurrentScreen(screenNames.order));
                      }}
                      sx={{
                        background: themeColors.primaryType1,
                      }}
                    >
                      +
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Box>
      <Box
        className="flex jc-c ai-c"
        height={"55px"}
        bgcolor={themeColors.secondaryType1}
      >
        <CustomButton
          onClick={() => {
            dispatch(setCurrentScreen(screenNames.checkout));
          }}
          width="130px"
          align="space-around"
        >
          <ShoppingCartOutlinedIcon sx={{ fontSize: 20 }} />
          <Typography fontSize={16}>{locale?.buttons?.checkout}</Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default BrandSelection;
