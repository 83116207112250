import axiosInstance from "../axios";
import { URLS } from "../constants/urls";
import store, { setLoading } from "../redux";
import {
  AccountsWithTriad,
  FiltredApiDataInterface,
  setFilteredApiData,
} from "../redux/reducer/order-slice";
import { AccountsInterface, CartOrder, DiscountRanges, OfferApiData, OrderHistory, SkuWithQuantity } from "../types";
import { sendEvent } from "./sendEvent";

const getFilteredOrderHistory = (data: OfferApiData, statusToCheck: string) => {
  const updatedOrderHistory = data.orderHistory
    .filter(
      (ele) =>
        ele.internalOrderStatus &&
        ele.internalOrderStatus.toLowerCase() === statusToCheck
    )
    .sort((a, b) => {
      const dateA = new Date(a.orderedDateString);
      const dateB = new Date(b.orderedDateString);
      return dateB.getTime() - dateA.getTime();
    });

  return updatedOrderHistory;
};

export const getFilteredOffersData = (data: OfferApiData) => {
  try {
    let brandAdded: string[] = [];
    let brandList: FiltredApiDataInterface[] = [];
    let openOrders: OrderHistory[] = [];
    let closeOrders: OrderHistory[] = [];
    let offerData = [];
    let lastOrder: OrderHistory | null = null;
    let startDate = "";
    let endDate = "";
    let accounts : AccountsInterface[] = []

    if (data) {
      if (data.clubbedOffers && data.clubbedOffers.length > 0) {
        for (let offerData of data.clubbedOffers) {
          if (
            offerData.brandIdentifier &&
            !brandAdded.includes(offerData.brandIdentifier)
          ) {
            brandAdded.push(offerData.brandIdentifier);

            let onInvoiceDiscountRanges: DiscountRanges[] = [];

            if (offerData.onInvoiceDiscountRanges) {
              onInvoiceDiscountRanges = offerData.onInvoiceDiscountRanges;
            }

            brandList.push({
              brand: offerData.brand,
              brandIdentifier: offerData.brandIdentifier,
              onInvoiceDiscountRanges,
              purchased: 0,
              selected: 0,
              offerLevel: offerData.offerLevel,
              skuData: [],
              maxVolume: offerData.maxVolume,
            });
          }
        }

        for (let brand of brandList) {
          const skuData = data.clubbedOffers
            .filter(
              (offerData) =>
                offerData.skuIdentifier &&
                offerData.brandIdentifier === brand.brandIdentifier
            )
            .map((offerData) => {
              return {
                brand: offerData.brand,
                brandIdentifier: offerData.brandIdentifier,
                ean: offerData.ean,
                maxLevelReached: offerData.maxLevelReached,
                maxVolume: offerData.maxVolume,
                offerLevel: offerData.offerLevel,
                onInvoiceDiscountRanges: offerData.onInvoiceDiscountRanges,
                period: offerData.period,
                sku: offerData.sku,
                skuIdentifier: offerData.skuIdentifier,
                skuPrice: offerData.skuPrice
              };
            });
          offerData.push({
            ...brand,
            skuData: skuData,
          });
        }
        startDate = data.clubbedOffers[0].period.startDateString;
        endDate = data.clubbedOffers[0].period.endDateString;
      }
      if (data.orderHistory) {
        openOrders = getFilteredOrderHistory(data, "open");
        closeOrders = getFilteredOrderHistory(data, "closed");
        if (data.orderHistory.length > 0) {
          lastOrder = data.orderHistory[data.orderHistory.length - 1];
        }
      }
      if(data.accounts){
        const accountWithTriad = data.accounts.map((account)=> {
          return {
            ...account,
            triad: account.wholesalerCode + account.accountCode + account.legalIdCode
          }
        }).filter(account => account.customerServiceAccountStatus === "VERIFIED" || account.customerServiceAccountStatus === "PENDING_VERIFICATION");
        accounts = accountWithTriad;
      }
    }

    return {
      lastOrder,
      openOrders,
      closeOrders,
      offerData,
      startDate,
      endDate,
      accounts: accounts
    };
  } catch (error) {
    return {
      openOrders: [],
      closeOrders: [],
      offerData: [],
      startDate: "",
      endDate: "",
      lastOrder: null,
      accounts: []
    };
  }
};

export const getBrandData = async () => {
  try {
    store.dispatch(setLoading(true));
    const state = store.getState();
    const authData = state.authenticationSlice;
    const locData = state.locSlice;
    const { data } = await axiosInstance.get(
      URLS.updatedOfferApi(authData.user.user_id, locData.loc as unknown as string, authData.user.phone)
    );
    store.dispatch(setLoading(false));
    if (data) {
      const updatedFilteredData = getFilteredOffersData(data);
      store.dispatch(setFilteredApiData(updatedFilteredData));
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    console.log(error);
  }
};

export const findLocaleLang = (data: any, language?: any) => {
  let defaultLang = data?.defaultLang;
  if (localStorage.getItem("current-lang")) {
    defaultLang = localStorage.getItem("current-lang");
  } else {
    localStorage.setItem("current-lang", defaultLang);
  }
  if (language) {
    defaultLang = language;
    localStorage.setItem("current-lang", language);
  }
  const localeData = data?.localization.find(
    (langData: any) => langData?.language === defaultLang
  );
  return localeData;
};

export const downloadExportFile = (response: any, type: any, fileName: any) => {
  const url = window.URL.createObjectURL(new Blob([response.data || response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", `_blank`);
  if (response.headers["content-disposition"].split("filename=")[1]) {
    link.setAttribute(
      "download",
      response.headers["content-disposition"]
        .split("filename=")[1]
        .split('"')[1]
    );
  } else {
    link.setAttribute("download", `${fileName || "Export"}.${type}`);
  }

  document.body.appendChild(link);
  link.click();
};

export const handleDeleteOrder = async (orderId: string) => {
  if (orderId) {
    try {
      store.dispatch(setLoading(true));
      const { status } = await axiosInstance.delete(URLS.deleteOrder(orderId));
      store.dispatch(setLoading(false));
      if (status === 200) {
        sendEvent("delete-order-success", {
          orderId: orderId,
        });
        await getBrandData();
      }
    } catch (e) {
      console.log(e);
    }
  }
};


export const getTriad = (order: CartOrder) =>{
  return order.wholesalerCode+order.accountCode+order.legalIdCode;
}


export const getBlankDraftOpenCard = (brandData:FiltredApiDataInterface, account: AccountsWithTriad,date:string) =>{
  const state = store.getState();
  const locData = state.locSlice;
  const authData = state.authenticationSlice;
  const skuData = brandData.skuData.map((sku) => {
    return {
      skuIdentifier: sku.skuIdentifier,
      sku: sku.sku,
      requestedVolume: 0,
      onInvoiceDiscount: 0,
      price: sku.skuPrice,
    };
  });
  return  {
      loc: locData.loc,
      orderType: "ORDER",
      brandIdentifier: brandData.brandIdentifier,
      brand: brandData.brand,
      skuData,
      phoneNumber: authData.user.phone,
      orderPlacedBy: authData.user.phone,
      accountCode: account.accountCode,
      orderDate: date,
      externalOrderStatus: "DRAFT",
      orderId: -1,
      legalIdCode: account.legalIdCode,
      wholesalerCode: account.wholesalerCode,
      wholesalerName: account.wholesalerName
    }
  
}


export const changeOrderAccount = (orderData:CartOrder[],order: CartOrder, account: AccountsWithTriad,date:string) =>{
  const updatedOrderData = orderData.map((orderItem) => {
    if (
      orderItem.brandIdentifier === order.brandIdentifier &&
      orderItem.orderDate === order.orderDate &&
      getTriad(orderItem) === getTriad(order)
    ) {
      return {
        ...orderItem,
        accountCode: account.accountCode,
        legalIdCode: account.legalIdCode,
        wholesalerCode: account.wholesalerCode,
        wholesalerName: account.wholesalerName,
        orderDate: date
      };
    } else {
      return orderItem;
    }
  });
  return updatedOrderData;
}

export const calculateDiscount = (
  DiscountRanges: DiscountRanges[],
  totalQunaity: number
) => {
  let discountValue: number | string = 0;
  if (DiscountRanges && DiscountRanges.length > 0) {
    for (let i = 0; i < DiscountRanges.length; i++) {
      if (i + 1 === DiscountRanges.length) {
        if (totalQunaity >= DiscountRanges[i].label) {
          discountValue = DiscountRanges[i].discount;
          break;
        }
      } else {
        if (totalQunaity >= DiscountRanges[i].label) {
          discountValue = DiscountRanges[i + 1].discount;
        }
      }
    }
    return discountValue;
  } else {
    return 0;
  }
};

export const createOrderDataWithDiscount = (
  orderData: CartOrder[],
  discountValue: string | number,
  skuWithTotalQuantity?: SkuWithQuantity[]
) => {
  const finalOrderData: CartOrder[] = [];
  for (let order of orderData) {
    const requestedVolumeSelected = order.skuData.find(
      (orderItem) => orderItem.requestedVolume > 0
    );
    if (requestedVolumeSelected) {
      const skuWithDiscount: {
        skuIdentifier: string;
        sku: string;
        requestedVolume: number;
        onInvoiceDiscount: number;
        price: number;
      }[] = [];
      for (let skuItem of order.skuData) {
        let findQunaitySku: {
              skuIdentifier: string;
              onInvoiceDiscountRanges: DiscountRanges[];
              totalQuantity: number;
            }
          | undefined = undefined;
        if (skuWithTotalQuantity) {
          findQunaitySku = skuWithTotalQuantity.find(
            (sku) => sku.skuIdentifier === skuItem.skuIdentifier
          );
        }
        skuWithDiscount.push({
          ...skuItem,
          onInvoiceDiscount:
            skuWithTotalQuantity && findQunaitySku
              ? (calculateDiscount(
                  findQunaitySku.onInvoiceDiscountRanges,
                  findQunaitySku.totalQuantity
                ) as number)
              : (discountValue as number),
        });
      }
      finalOrderData.push({
        ...order,
        skuData: skuWithDiscount,
      });
    }
  }
  return finalOrderData;
};