import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RootScreen from "./screens/RootScreen";
import "./assets/css/global.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import WABA from "./screens/Authenticated/WABA/WABA";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f26633",
      light: "#F7EAE1",
    },
    secondary: {
      main: "#959595",
    },
    success: {
      main: "#008a00",
      light: "#4caf50",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
    },
  },
  typography: {
    fontSize: 12,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<RootScreen loc="Master" />} />
            <Route path="/waba" element={<WABA/>} />
          </Routes>
        </HashRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
