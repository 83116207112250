export const screenNames = {
  welcomeScreen: "welconeScreen",
  userWelcomeScreen: "userWelcomeScreen",
  termAndCondition: "termsAndCondition",
  brandSelectionScreen: "brandSelectionScreen",
  order: "order",
  userProfile: "userProfile",
  botLoadLanguage: "bot-load-language",
  orderHistory: "orderHistory",
  registration: 'registration',
  checkoutForm: 'checkoutForm',
  clientSelection: 'clientSelection',
  checkout: 'Checkout'
};
