import { createSlice } from "@reduxjs/toolkit";
import { AccountsInterface, CartOrder, DiscountRanges, OrderHistory } from "../../types";

export interface skuDataInterface {
  brand: string;
  brandIdentifier: string;
  ean: string;
  maxLevelReached: boolean;
  maxVolume: number;
  offerLevel: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  period: {
    endDateString: string;
    period: string;
    startDateString: string;
  };
  sku: string | null;
  skuIdentifier: string | null;
  skuPrice: number;
}
export interface FiltredApiDataInterface {
  brand: string;
  brandIdentifier: string;
  onInvoiceDiscountRanges: DiscountRanges[];
  purchased: number;
  selected: number;
  offerLevel: string;
  skuData: skuDataInterface[];
  maxVolume: number;
}

export interface AccountsWithTriad extends AccountsInterface {
  triad: string;
}

interface OrderState {
  orderData: CartOrder[];
  cart: CartOrder[];
  filteredApiData: {
    offerData: FiltredApiDataInterface[];
    openOrders: OrderHistory[];
    closeOrders: OrderHistory[];
    lastOrder: OrderHistory | null;
    startDate: string;
    endDate: string;
    accounts: AccountsWithTriad[]
  };
  selectedBrand: FiltredApiDataInterface
}

const orderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    orderData: [],
    filteredApiData: {
      offerData: [],
      openOrders: [],
      closeOrders:[],
      lastOrder: null,
      startDate: "",
      endDate: "",
      accounts: []
    },
    selectedBrand: {},
    cart: []
  },
  reducers: {
    setCart(state,action){
      state.cart = action.payload;
    },
    setOrderData(state, action) {
      state.orderData = action.payload;
    },
    setFilteredApiData(state, action) {
      state.filteredApiData = action.payload;
    },
    setSelectedBrand(state,action){
      state.selectedBrand = action.payload;
    }
  },
});

export default orderSlice.reducer;

export const { setOrderData, setFilteredApiData, setSelectedBrand, setCart } = orderSlice.actions;

export const OrderData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.orderData;

export const FilteredApiData = (state: { orderSlice: OrderState }) =>
  state.orderSlice.filteredApiData;

export const SelectedBrand = (state: {orderSlice: OrderState}) => state.orderSlice.selectedBrand;

export const Cart = (state: {orderSlice:OrderState}) => state.orderSlice.cart;