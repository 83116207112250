import * as React from "react";
import "./index.css";
import { Box } from "@mui/material";

interface ToggleButtonProps {
  flag: boolean;
  keyName: string;
  handleOnClick: (keyName: string, updatedValue: boolean) => Promise<void>;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  flag,
  keyName,
  handleOnClick,
}) => {
  return (
    <Box
      className="switch-outerBox"
      onClick={() => {
        handleOnClick(keyName, !flag);
      }}
      sx={{
        background: flag ? "#2ECA45" : "#FF4C50",
        justifyContent: flag ? "flex-end" : "flex-start",
      }}
    >
      <Box className="switch-innerBox" />
    </Box>
  );
};

export default ToggleButton;
