/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ProgressCard from "../ProgressCard/ProgressCard";
import { DiscountRanges } from "../../utils/types";
import { useSelector } from "react-redux";
import { SelectedBrand } from "../../utils/redux";
import { OrderData, skuDataInterface } from "../../utils/redux/reducer/order-slice";

interface OfferGadgetProps {
  discountRanges: DiscountRanges[];
  isSKUOffer: boolean;
  skuData?: skuDataInterface
}

const OfferGadget: FC<OfferGadgetProps> = ({ discountRanges, isSKUOffer, skuData }) => {
  const brandData = useSelector(SelectedBrand);
  const orderData = useSelector(OrderData);
  const [selected,setSelected] = useState(0);

  useEffect(()=>{
    if(orderData.length>0){
      let totalUnits = 0;
      for(let orderItem of orderData){
        if(skuData){
        const skuOrderData = orderItem.skuData.filter(skuItem => skuItem.skuIdentifier===skuData?.skuIdentifier);
        for(let skuOrderItem of skuOrderData){
          totalUnits = totalUnits + skuOrderItem.requestedVolume;
        }
      }else{
        if(orderItem.brandIdentifier===brandData.brandIdentifier){
          for(let skuOrderItem of orderItem.skuData){
            totalUnits = skuOrderItem.requestedVolume + totalUnits;
          }
        }
      }
    }
      setSelected(totalUnits);
    }
  },[orderData])

  return (
    <Box>
      <Grid container>
        {isSKUOffer && <Grid item width={'110px'}/>}
        {discountRanges
          ?.slice(0, discountRanges.length - 1)
          ?.map((discount, index: number) => {
            return (
              <Grid xs item key={index} className="flex jc-fe ai-fe">
                <Typography>{discountRanges[index + 1].discount}</Typography>
              </Grid>
            );
          })}
        <Grid item xs className="tar">
          <Typography>On Invoice</Typography>
        </Grid>
      </Grid>
      <ProgressCard
        skuData={skuData}
        isSKUOffer={isSKUOffer}
        purchased={brandData.purchased}
        selected={selected}
        discountRange={discountRanges}
        brand={brandData}
      />
      <Grid container>
      {isSKUOffer && <Grid item width={'110px'}/>}
        {discountRanges
          ?.slice(0, discountRanges.length - 1)
          ?.map((discount: any, index: number) => {
            return (
              <Grid
                xs
                item
                key={index}
                className={`flex ${index === 0 ? "jc-sb" : "jc-fe"}`}
              >
                {index === 0 && <Typography>Value</Typography>}
                <Typography>{discount.label}</Typography>
              </Grid>
            );
          })}
        <Grid item xs className="tar">
          <Typography>{brandData.maxVolume}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferGadget;
