import "./styles.css";
import { Box, Typography } from "@mui/material";
import CustomAccordian from "../CustomAccordian/CustomAccordian";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { themeColors } from "../../utils/constants/themeColors";
import { FC, useState } from "react";
import DatePicker from "react-datepicker";
import { CartOrder } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { FilteredApiData, OrderData, setOrderData } from "../../utils/redux";
import dayjs from "dayjs";
import {
  changeOrderAccount,
  getTriad,
} from "../../utils/helperFunctions/helperFunction";
import DialogBox from "../DialogBox/DialogBox";
import { AccountsWithTriad, SelectedBrand } from "../../utils/redux/reducer/order-slice";
import { offerTypes } from "../../utils/constants/constatns";

interface OpenOderCardProps {
  order: CartOrder;
  disableDates: {
    triad: string;
    dateToExclude: string[];
  }[];
  hideAddWholesaler: boolean;
}

const OpenOderCard: FC<OpenOderCardProps> = ({ order, disableDates, hideAddWholesaler }) => {
  const [open, setOpen] = useState(false);
  const orderData = useSelector(OrderData);
  const dispatch = useDispatch();
  const filtredData = useSelector(FilteredApiData);
  const [wholesalerChange, setShowWholesalerChange] = useState(false);
  const brandData = useSelector(SelectedBrand);

  const handleQunatity = (
    sku: {
      skuIdentifier: string;
      sku: string;
      requestedVolume: number;
      onInvoiceDiscount: number;
      price: number;
    },
    operation: string
  ) => {
    if (operation === "minus" && sku.requestedVolume === 0) {
      return;
    }
    if (operation === "plus") {
      let totalQuantity = 0;
      if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
        if (hideAddWholesaler) {
          alert("Maximum unit reached!");
          return;
        }
      } else {
        for (let orderItem of orderData) {
          for (let skuItem of orderItem.skuData) {
            if (skuItem.skuIdentifier === sku.skuIdentifier) {
              totalQuantity = totalQuantity + skuItem.requestedVolume;
            }
          }
        }
        const findSku = brandData.skuData.find(
          (skuItem) => skuItem.skuIdentifier === sku.skuIdentifier
        );
        if (findSku && totalQuantity >= findSku.maxVolume) {
          alert("Maximum unit reached!");
          return;
        }
      }
    }
    const updatedOrderData: CartOrder[] = [];
    for (let orderItem of orderData) {
      if (orderItem.orderDate === order.orderDate) {
        const updatedSkuData = orderItem.skuData.map((skuItem) => {
          if (
            skuItem.skuIdentifier === sku.skuIdentifier &&
            getTriad(orderItem) === getTriad(order)
          ) {
            return {
              ...skuItem,
              requestedVolume:
                operation === "minus"
                  ? skuItem.requestedVolume - 1
                  : skuItem.requestedVolume + 1,
            };
          } else {
            return skuItem;
          }
        });
        updatedOrderData.push({ ...orderItem, skuData: updatedSkuData });
      } else {
        updatedOrderData.push(orderItem);
      }
    }
    dispatch(setOrderData(updatedOrderData));
  };

  const handleDateChange = (date: Date) => {
    const updatedOrderData = orderData.map((orderItem) => {
      if (
        orderItem.brandIdentifier === order.brandIdentifier &&
        orderItem.orderDate === order.orderDate &&
        getTriad(orderItem) === getTriad(order)
      ) {
        return {
          ...orderItem,
          orderDate: dayjs(date).format("YYYY-MM-DD"),
        };
      } else {
        return orderItem;
      }
    });
    dispatch(setOrderData(updatedOrderData));
    setOpen(false);
  };

  const getDateToDisable = () => {
    if (disableDates.length > 0) {
      const findDisableDates = disableDates.find(
        (data) => data.triad === getTriad(order)
      );
      if (findDisableDates) {
        const updatedDataInDateFormat = findDisableDates.dateToExclude
          .filter((date) => date !== order.orderDate)
          .map((date) => new Date(date));
        return updatedDataInDateFormat;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getAvailableDateForWholesaler = (
    date: string,
    existingOrder: CartOrder[],
    account: AccountsWithTriad
  ) => {
    const isExist = existingOrder.find((order) => order.orderDate === date);
    if (isExist) {
      if (date === filtredData.endDate) {
        alert("no more date available");
      } else {
        let nextDate = dayjs(date).add(1, "day").format("YYYY-MM-DD");
        getAvailableDateForWholesaler(nextDate, existingOrder, account);
      }
    } else {
      const updatedOrderData = changeOrderAccount(
        orderData,
        order,
        account,
        date
      );
      dispatch(setOrderData(updatedOrderData));
      setOpen(true);
    }
  };

  const handleWholesalerChange = (account: AccountsWithTriad) => {
    const existingOrder = orderData.filter(
      (orderItem) => getTriad(orderItem) === account.triad
    );
    if (existingOrder.length > 0) {
      const sameDateOrderExist = existingOrder.find(
        (orderItem) => orderItem.orderDate === order.orderDate
      );
      if (sameDateOrderExist) {
        getAvailableDateForWholesaler(
          dayjs().format("YYYY-MM-DD"),
          existingOrder,
          account
        );
      } else {
        const updatedOrderData = changeOrderAccount(
          orderData,
          order,
          account,
          order.orderDate
        );
        dispatch(setOrderData(updatedOrderData));
      }
    } else {
      const updatedOrderData = changeOrderAccount(
        orderData,
        order,
        account,
        order.orderDate
      );
      dispatch(setOrderData(updatedOrderData));
    }
    setShowWholesalerChange(false);
  };

  return (
    <>
      <CustomAccordian
        sx={{ paddingTop: 0, paddingLeft: "23px" }}
        title={
          <Box className="flex ai-c jc-sb fullWidth">
            <Typography fontSize={14}>
              {order.wholesalerName} - {order.accountCode}
            </Typography>
            <Box
              className="flex ai-c pointer open-order-date-picker"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <CalendarMonthIcon
                sx={{ fontSize: 16 }}
                onClick={(event) => {
                  setOpen(true);
                }}
              />
              <DatePicker
                excludeDates={getDateToDisable()}
                onInputClick={() => {
                  setOpen(true);
                }}
                popperPlacement="bottom-start"
                readOnly={true}
                open={open}
                onClickOutside={() => {
                  setOpen(false);
                }}
                autoComplete="off"
                className="card-date-picker"
                dateFormat="MMM dd, yy"
                selected={new Date(dayjs(order.orderDate).format("YYYY-MM-DD"))}
                value={dayjs(order.orderDate).format("MMM DD, YY")}
                onChange={(date) => {
                  if (date) {
                    handleDateChange(date);
                  }
                }}
                minDate={new Date(new Date().toISOString().split("T")[0])}
                maxDate={
                  !!filtredData.endDate
                    ? new Date(filtredData.endDate)
                    : new Date(new Date().toISOString().split("T")[0])
                }
              />
            </Box>
          </Box>
        }
        showEdit={true}
        setShowAccountList={setShowWholesalerChange}
      >
        {order.skuData.map((sku) => {
          return (
            <Box className="mt5" key={sku.skuIdentifier}>
              <Box className="flex ai-c jc-sb">
                <Typography fontSize={12.5}>{sku.sku}</Typography>
                <Box className="flex us-remove">
                  <Box
                    className="quantity-handle-button minus-button flex ai-c jc-c"
                    bgcolor={themeColors.primaryType1}
                    onClick={() => handleQunatity(sku, "minus")}
                  >
                    <Typography fontWeight={500}>-</Typography>
                  </Box>
                  <Box
                    className="selected-quantity flex ai-c jc-c"
                    bgcolor={themeColors.secondaryType1}
                  >
                    <Typography>{sku.requestedVolume}</Typography>
                  </Box>
                  <Box
                    className="quantity-handle-button plus-button flex ai-c jc-c"
                    bgcolor={themeColors.primaryType1}
                    onClick={() => handleQunatity(sku, "plus")}
                  >
                    <Typography fontWeight={500}>+</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </CustomAccordian>
      <DialogBox open={wholesalerChange} setOpen={setShowWholesalerChange}>
        <Box className="p10 accountL-list-container">
          {filtredData.accounts.length === 1 ? (
            <Box
              onClick={() => {
                setShowWholesalerChange(false);
              }}
              className="account-list-item"
            >
              <Typography fontSize={14}>
                {filtredData.accounts[0].wholesalerName} -{" "}
                {filtredData.accounts[0].accountCode}
              </Typography>
            </Box>
          ) : (
            filtredData.accounts.map((account) => {
              return (
                account.triad !== getTriad(order) && (
                  <Box
                    onClick={() => {
                      handleWholesalerChange(account);
                    }}
                    key={account.triad}
                    className="account-list-item"
                  >
                    <Typography fontSize={14}>
                      {account.wholesalerName} - {account.accountCode}
                    </Typography>
                  </Box>
                )
              );
            })
          )}
        </Box>
      </DialogBox>
    </>
  );
};

export default OpenOderCard;
