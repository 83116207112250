import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
// import { fetchFromStorage } from "../storage";
// import { identifiers } from "../constants/identifiers";
import Config from "../config";
import { fetchFromStorage } from "../storage";
import { identifiers } from "../constants/identifiers";
// import store from "../redux";
// import { logout, setCurrentScreen } from "../redux/reducer/authentication-slice";
// import { screenNames } from "../constants/screenNames";

const axiosInstance = Axios.create({
  baseURL: Config.BaseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  // let token = "e53517bd-2c88-4b13-8b48-15dbf097f3d0";
  const token = fetchFromStorage(identifiers.token);
  const clonedConfig: any = config;

  if (token) {
    clonedConfig.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return clonedConfig;
});
axiosInstance.interceptors.response.use(
  (config: AxiosResponse) => {
    return config;
  },
  (error: AxiosError) => {
    // Handle token expire
    // if(error.response?.status===401){
    //   store.dispatch(logout()); 
    //   store.dispatch(setCurrentScreen(screenNames.welcomeScreen));
    // }    
      return Promise.reject(error);
  }
);

export default axiosInstance;
