import { Box, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { Cart, Locale, setCurrentScreen, setOrderData } from "../../../utils/redux";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { screenNames } from "../../../utils/constants/screenNames";
import "./styles.css";
import CustomAccordian from "../../../components/CustomAccordian/CustomAccordian";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";

const Checkout = () => {
  const locale = useSelector(Locale);
  const cart = useSelector(Cart);
  const dispatch = useDispatch();
  return (
    <Box className="flex fdc fullHeight borderBox">
      <Box flex={1} className="auto-o pl20 pr20">
        <Box className="flex ai-c jc-sb"  mt={1}  >
          <Typography color={"primary"} fontSize={20}>
            Checkout
          </Typography>
          <Box
            className="flex ai-c jc-c pointer us-remove"
            bgcolor={"primary.main"}
            height={"20px"}
            width={"20px"}
            borderRadius={"100%"}
            onClick={()=>{
                dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
            }}
          >
            <Typography fontSize={16} fontWeight={500} color={"white"}>
              +
            </Typography>
          </Box>
        </Box>
        {cart.map((cartItem)=>{
          return <Box mt={1}>
          <CustomAccordian
            defaultOpen={true}
            sx={{ paddingTop: 0 }}
            customHeight={30}
            expandedHeight={30}
            title={
              <Box className="flex fullWidth jc-sb">
                <Typography fontSize={14}>{cartItem.wholesalerName} - {cartItem.accountCode}</Typography>
                <Box className="flex ai-c g5 mr10">
                  <CalendarMonthIcon sx={{ fontSize: 16 }} />
                  <Typography fontSize={14}>{dayjs(cartItem.orderDate).format("MMM D, YYYY")}</Typography>
                </Box>
              </Box>
            }
          >
            <Box>
              <Box className="flex">
                <Typography flex={2} color={"primary"}>
                  Product
                </Typography>
                <Typography flex={1} color={"primary"}>
                  Price
                </Typography>
                <Typography flex={1} color={"primary"}>
                  Unit
                </Typography>
                <Typography flex={1} color={"primary"}>
                  Discount
                </Typography>
              </Box>
              {cartItem.skuData.map((skuItem,index)=>{
                return skuItem.requestedVolume>0 && <Box key={index} className="flex">
                <Typography flex={2}>{skuItem.sku}</Typography>
                <Typography flex={1}>${skuItem.price}</Typography>
                <Typography flex={1}>{skuItem.requestedVolume}</Typography>
                <Typography flex={1}>{skuItem.onInvoiceDiscount}%</Typography>
              </Box>
              })}
            </Box>
          </CustomAccordian>
        </Box>
        })}
      </Box>
      <Box className="flex jc-c g20 pt5 pb5">
        <CustomButton
          onClick={() => {dispatch(setCurrentScreen(screenNames.brandSelectionScreen));}}
          align="space-evenly"
          width={"155px"}
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece"
        >
          <ArrowCircleLeftOutlinedIcon />
          <Typography fontSize={16}>{locale.buttons.back}</Typography>
        </CustomButton>
        <CustomButton
          onClick={() => {
            dispatch(setOrderData([]));
            dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
          }}
          align={"space-evenly"}
          width={"155px"}
        >
          <Typography fontSize={16}>Confirm</Typography>
          <ArrowCircleRightOutlinedIcon />
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Checkout;
