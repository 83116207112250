/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseOrderCard from "../../../components/CloseOrderCard/CloseOrderCard";
import OpenOderCard from "../../../components/OpenOderCard/OpenOderCard";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Cart,
  FilteredApiData,
  Loc,
  Locale,
  OrderData,
  SelectedBrand,
  setCurrentScreen,
  setOrderData,
} from "../../../utils/redux";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { screenNames } from "../../../utils/constants/screenNames";
import OfferGadget from "../../../components/OfferGadget/OfferGadget";
import DialogBox from "../../../components/DialogBox/DialogBox";
import "./index.css";
import { themeColors } from "../../../utils/constants/themeColors";
import { offerTypes } from "../../../utils/constants/constatns";
import { CartOrder, OrderHistory, SkuWithQuantity } from "../../../utils/types";
import dayjs from "dayjs";
import {
  calculateDiscount,
  createOrderDataWithDiscount,
  getBlankDraftOpenCard,
  getTriad,
} from "../../../utils/helperFunctions/helperFunction";
import {
  AccountsWithTriad,
  setCart,
} from "../../../utils/redux/reducer/order-slice";

const Order = () => {
  const locale = useSelector(Locale);
  const [showAccountList, setShowAccountList] = useState(false);
  const dispatch = useDispatch();
  const brandData = useSelector(SelectedBrand);
  const filtredData = useSelector(FilteredApiData);
  const [closeOrders, setCloseOrders] = useState<OrderHistory[]>([]);
  const orderData = useSelector(OrderData);
  const cartData = useSelector(Cart);
  const [hideAddWholesaler, setHideAddWholesaler] = useState(false);
  const [disableDates, setDisableDates] = useState<
    { triad: string; dateToExclude: string[] }[]
  >([]);
  const loc = useSelector(Loc);

  const handleWholesalerSelection = (
    triad: string,
    account: AccountsWithTriad,
    nextDate?: string
  ) => {
    let dateToCheck = nextDate ? nextDate : dayjs().format("YYYY-MM-DD");
    const isExist = orderData.find(
      (order) => order.orderDate === dateToCheck && triad === getTriad(order)
    );
    if (isExist) {
      if (dateToCheck === filtredData.endDate) {
        alert("no more date available");
      } else {
        let nextDate = dayjs(dateToCheck).add(1, "day").format("YYYY-MM-DD");
        handleWholesalerSelection(triad, account, nextDate);
      }
    } else {
      const blankOpenCard = getBlankDraftOpenCard(
        brandData,
        account,
        dateToCheck
      );
      dispatch(setOrderData([blankOpenCard, ...orderData]));
    }
  };

  const handleContinue = () => {
    let totalQunaity = 0;
    for (let order of orderData) {
      for (let skuItem of order.skuData) {
        totalQunaity = skuItem.requestedVolume + totalQunaity;
      }
    }
    const exitingCartWithoutCurrentBrand = cartData.filter(
      (cartItem) => cartItem.brandIdentifier !== brandData.brandIdentifier
    );

    let finalData: CartOrder[] = [];
    if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
      let discountValue = calculateDiscount(
        brandData.onInvoiceDiscountRanges,
        totalQunaity
      );
      finalData = createOrderDataWithDiscount(orderData, discountValue);
    } else {
      const skuWithQuantity: SkuWithQuantity[] = [];
      for (let skuItem of brandData.skuData) {
        let totalQuantity = 0;
        for (let orderItem of orderData) {
          for (let orderSku of orderItem.skuData) {
            if (orderSku.skuIdentifier === skuItem.skuIdentifier) {
              totalQuantity = totalQuantity + orderSku.requestedVolume;
            }
          }
        }
        skuWithQuantity.push({
          skuIdentifier: skuItem.skuIdentifier as string,
          onInvoiceDiscountRanges: skuItem.onInvoiceDiscountRanges,
          totalQuantity,
        });
      }
      finalData = createOrderDataWithDiscount(orderData, 0, skuWithQuantity);
    }

    const finalDraft = [];
    for (let cartItem of [...exitingCartWithoutCurrentBrand, ...finalData]) {
      for (let cartSkuItem of cartItem.skuData) {
        if (cartSkuItem.requestedVolume > 0) {
          finalDraft.push({
                loc: loc,
                orderType: cartItem.orderType,
                internalOrderNumber: "",
                internalOrderStatus: "OPEN",
                externalOrderNumber: "",
                externalOrderDate: "",
                brandIdentifier: cartItem.brandIdentifier,
                brand: cartItem.brand,
                skuIdentifier: cartSkuItem.skuIdentifier,
                sku: cartSkuItem.sku,
                requestedVolume: cartSkuItem.requestedVolume,
                invoicedVolume: "",
                onInvoiceDiscount: cartSkuItem.onInvoiceDiscount,
                onInvoiceFreeGoods: "",
                onInvoicePaymentTerms: "",
                offInvoiceDiscount: "",
                offInvoiceFreeGoods: "",
                phoneNumber: cartItem.phoneNumber,
                orderPlacedBy: cartItem.orderPlacedBy,
                accountCode: cartItem.accountCode,
                draftDate: cartItem.orderDate,
                externalOrderStatus: "DRAFT",
                noOfPharmaciesOrder: 1,
                draftId: -1,
                legalIdCode: cartItem.legalIdCode,
              });
        }
      }
    }

    console.log("finalDraft::", {draftDetails: [...finalDraft]});

    dispatch(setCart([...exitingCartWithoutCurrentBrand, ...finalData]));
    dispatch(setOrderData([]));
    dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
  };

  useEffect(() => {
    if (filtredData.closeOrders) {
      const filtredCloseOrders: OrderHistory[] = [];
      for (let closeOrder of filtredData.closeOrders) {
        if (closeOrder.currentPeriod) {
          const orderWithCurrentBrand = closeOrder.orderLineItems.filter(
            (closeOrder) =>
              closeOrder.brandIdentifier === brandData.brandIdentifier
          );
          if (orderWithCurrentBrand.length > 0) {
            filtredCloseOrders.push({
              ...closeOrder,
              orderLineItems: orderWithCurrentBrand,
            });
          }
        }
      }
      setCloseOrders(filtredCloseOrders);
    }
  }, []);

  useEffect(() => {
    if (filtredData.accounts.length > 0) {
      if (cartData) {
        const isAvailableInCart = cartData.find(
          (cartItem) => cartItem.brandIdentifier === brandData.brandIdentifier
        );
        if (isAvailableInCart) {
          const existingCartData = cartData.filter(
            (cartItem) => cartItem.brandIdentifier === brandData.brandIdentifier
          );
          dispatch(setOrderData(existingCartData));
        } else {
          const blankOpenCard = getBlankDraftOpenCard(
            brandData,
            filtredData.accounts[0],
            dayjs().format("YYYY-MM-DD")
          );
          dispatch(setOrderData([blankOpenCard]));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (orderData.length > 0) {
      const updatedDisableDates = [];
      for (let account of filtredData.accounts) {
        const dateToDisable = orderData
          .filter((order) => getTriad(order) === account.triad)
          .map((order) => {
            return order.orderDate;
          });
        updatedDisableDates.push({
          triad: account.triad,
          dateToExclude: dateToDisable,
        });
      }
      setDisableDates(updatedDisableDates);
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData.length > 0) {
      if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
        let totalQuantity = 0;
        if (brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel) {
          for (let order of orderData) {
            for (let skuData of order.skuData) {
              totalQuantity = totalQuantity + skuData.requestedVolume;
            }
          }
          if (totalQuantity >= brandData.maxVolume) {
            setHideAddWholesaler(true);
          } else {
            setHideAddWholesaler(false);
          }
        }
      } else {
        let maxReached: boolean[] = [];
        for (let skuDataItem of brandData.skuData) {
          let totalUnits = 0;
          for (let orderItem of orderData) {
            const findSku = orderItem.skuData.find(
              (orderSkuItem) =>
                orderSkuItem.skuIdentifier === skuDataItem.skuIdentifier
            );
            if (findSku) {
              totalUnits = totalUnits + findSku.requestedVolume;
            }
          }
          if (totalUnits >= skuDataItem.maxVolume) {
            maxReached.push(true);
          } else {
            maxReached.push(false);
          }
        }
        const isAllMaxReached = maxReached.find((item) => item === false);
        if (isAllMaxReached === false) {
          setHideAddWholesaler(false);
        } else {
          setHideAddWholesaler(true);
        }
      }
    }
  }, [orderData]);

  return (
    <Box className="pt20 flex fdc fullHeight borderBox">
      <Box className="pl20 pr20">
        <Typography color={"primary"} fontSize={16}>
          {brandData.brand}
        </Typography>
        <Box className="flex jc-sb mb5 mt5">
          <Typography fontSize={12}>
            <span style={{ color: themeColors.primaryType1 }}>
              {locale?.labels?.startDate}:{" "}
            </span>
            {filtredData.startDate}
          </Typography>
          <Typography fontSize={12}>
            <span style={{ color: themeColors.primaryType1 }}>
              {locale?.labels?.endDate}:{" "}
            </span>
            {filtredData.endDate}
          </Typography>
        </Box>
        <Box className="br5" border={"1px solid #959595"} padding={"18px 12px"}>
          {brandData.offerLevel?.toLowerCase() === offerTypes.brandLevel && (
            <OfferGadget
              isSKUOffer={false}
              discountRanges={brandData.onInvoiceDiscountRanges}
            />
          )}
          {brandData.offerLevel?.toLowerCase() === offerTypes.skuLevel &&
            brandData.skuData.map((sku) => {
              return (
                <OfferGadget
                  key={sku.skuIdentifier}
                  discountRanges={sku.onInvoiceDiscountRanges}
                  isSKUOffer={true}
                  skuData={sku}
                />
              );
            })}
        </Box>
        <Box textAlign={"center"} mt={1.5} height={"28px"}>
          {!hideAddWholesaler && (
            <CustomButton
              background="white"
              hoverBackground="primary.main"
              color="primary"
              align="center"
              onClick={() => {
                setShowAccountList(true);
              }}
              padding="3px 20px"
            >
              <Typography fontSize={14}>Select Wholesaler Account</Typography>
            </CustomButton>
          )}
        </Box>
      </Box>
      <Box flex={1} className="auto-o pl20 pr20" mt={1.5}>
        {orderData &&
          orderData.map((order, index) => {
            return (
              <OpenOderCard
                hideAddWholesaler={hideAddWholesaler}
                disableDates={disableDates}
                key={index}
                order={order}
              />
            );
          })}
        {closeOrders.map((closeOrder) => {
          return (
            <CloseOrderCard key={closeOrder.orderId} closeOrder={closeOrder} />
          );
        })}
      </Box>
      <Box className="flex jc-c g20 pt5 pb5">
        <CustomButton
          onClick={() => {
            dispatch(setOrderData([]));
            dispatch(setCurrentScreen(screenNames.brandSelectionScreen));
          }}
          align="space-evenly"
          width={"155px"}
          background="#e6e6e6"
          color="#959595"
          hoverBackground="#d2cece"
        >
          <ArrowCircleLeftOutlinedIcon />
          <Typography fontSize={16}>{locale.buttons.back}</Typography>
        </CustomButton>
        <CustomButton
          onClick={handleContinue}
          align={"space-evenly"}
          width={"155px"}
        >
          <Typography fontSize={16}>{locale.buttons.continue}</Typography>
          <ArrowCircleRightOutlinedIcon />
        </CustomButton>
      </Box>
      <DialogBox open={showAccountList} setOpen={setShowAccountList}>
        <Box className="p10 accountL-list-container">
          {filtredData.accounts.map((account) => {
            return (
              <Box
                onClick={() => {
                  setShowAccountList(false);
                  handleWholesalerSelection(account.triad, account);
                }}
                key={account.triad}
                className="account-list-item"
              >
                <Typography fontSize={14}>
                  {account.wholesalerName} - {account.accountCode}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </DialogBox>
    </Box>
  );
};

export default Order;
