import "./styles.css";
import { Box, Divider, Typography } from "@mui/material";
import { themeColors } from "../../utils/constants/themeColors";
import { useSelector } from "react-redux";
import { Locale } from "../../utils/redux";
import CustomAccordian from "../CustomAccordian/CustomAccordian";
import useIsMobile from "../../utils/customHook/useIsMobile";
import { OrderHistory } from "../../utils/types";
import { FC } from "react";
import dayjs from "dayjs";

interface CloseOrderCardProps {
  closeOrder: OrderHistory;
}

const CloseOrderCard: FC<CloseOrderCardProps> = ({ closeOrder }) => {
  const locale = useSelector(Locale);
  const isMobile = useIsMobile();

  return (
    <CustomAccordian
      title={
        <Box className="flex ai-c jc-sb fullWidth">
          <Typography fontSize={14}>
            {closeOrder.wholesalerName} - {closeOrder.accountCode}
          </Typography>
          <Typography fontSize={14} mr={"10px"}>
            {dayjs(closeOrder.orderedDateString).format("MMM D, YYYY")}
          </Typography>
        </Box>
      }
      background="#3f3f3f"
      color="#959595"
      iconColor="white"
    >
      <Box>
        <Box
          className="flex jc-sb mt10"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: "5px", sm: "0px" }}
        >
          <Typography className="flex g5">
            <Typography color={themeColors.primaryType1} component="span">
              {locale.labels.orderNo}
            </Typography>
            {closeOrder.orderId}
          </Typography>
          <Typography className="flex g5">
            <Typography color={themeColors.primaryType1} component="span">
              Estado:
            </Typography>
            {closeOrder.status}
          </Typography>
        </Box>
        <Typography className="locale-products-label">
          {locale.labels.products}
        </Typography>
        <Box minWidth={isMobile ? "auto" : "auto"} overflow={"auto"}>
          <Box className="flex ai-fe">
            <Typography flex={2} color={themeColors.primaryType1}>
              SKU
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              {locale.labels.estPrice}
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              {locale.labels.units}
            </Typography>
            <Typography flex={1} color={themeColors.primaryType1}>
              Discount
            </Typography>
          </Box>
          <Divider
            sx={{
              background: "white",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
          {closeOrder.orderLineItems.map((order) => {
            return (
              <Box className="flex" key={order.skuIdentifier}>
                <Typography flex={2} fontSize={15} marginBottom="5px">
                  {order.skuIdentifier}
                </Typography>
                <Typography flex={1}>€ {order.price}</Typography>
                <Typography flex={1}>{order.units}</Typography>
                <Typography flex={1}>{order.onInvoiceDiscount}%</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CustomAccordian>
  );
};

export default CloseOrderCard;
