import BrandSelection from "../../screens/Authenticated/BrandSelection/BrandSelection";
import Checkout from "../../screens/Authenticated/Checkout/Checkout";
import CheckoutForm from "../../screens/Authenticated/CheckoutForm/CheckoutForm";
import ClientSelection from "../../screens/Authenticated/ClientSelection/ClientSelection";
import Order from "../../screens/Authenticated/Order/Order";
import UserProfile from "../../screens/Authenticated/UserProfile/UserProfile";
import UserWelcomeScreen from "../../screens/Authenticated/UserWelcomeScreen/UserWelcomeScreen";
import RegistrationScreen from "../../screens/Authentication/RegistrationScreen/RegistrationScreen";
import TermsCondition from "../../screens/Authentication/TermsCondition/TermsCondition";
import WelcomeScreen from "../../screens/Authentication/WelcomeScreen/WelcomeScreen";
import { eventNames } from "../constants/events";
import { screenNames } from "../constants/screenNames";

const changeScreen = (screenName: string) => {
  if (screenName === screenNames.welcomeScreen) {
    return <WelcomeScreen />;
  }
  if (screenName === screenNames.userWelcomeScreen) {
    return <UserWelcomeScreen />;
  }

  if (screenName === screenNames.termAndCondition) {
    return <TermsCondition />;
  }

  if (screenName === screenNames.brandSelectionScreen) {
    return <BrandSelection/>;
  }

  if (screenName === screenNames.order) {
    return <Order/>;
  }

  if (screenName === screenNames.userProfile) {
    return <UserProfile/>
  }

  if (screenName === screenNames.orderHistory) {
    return <CheckoutForm isOrderHistory={true}/>;
  }
  
  if (screenName === eventNames.regUnfilledStep) {
    return <RegistrationScreen event={eventNames.regUnfilledStep} />;
  }
  if (screenName === eventNames.regStep1) {
    return <RegistrationScreen event={eventNames.regStep1} />;
  }
  if (screenName === eventNames.regStep2) {
    return <RegistrationScreen event={eventNames.regStep2} />;
  }
  if (screenName === eventNames.regStep3) {
    return <RegistrationScreen event={eventNames.regStep3} />;
  }

  if(screenName === screenNames.checkoutForm){
    return <CheckoutForm isOrderHistory={false}/>;
  }
  
  if(screenName === screenNames.clientSelection){
    return <ClientSelection />;
  }

  if(screenName === screenNames.checkout){
    return <Checkout />;
  }
};
export default changeScreen;
