/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import {
  BannerLoaded,
  CurrentScreen,
  isAuthenticated,
  isLoading,
  Locale,
  setCurrentScreen,
  setLoc,
  setLocale,
  User,
} from "../utils/redux";
import { FC, useEffect, useState } from "react";
import { handleEvents } from "../utils/helperFunctions/handleEvents";
import { screenNames } from "../utils/constants/screenNames";
import { Box } from "@mui/material";
import changeScreen from "../utils/hooks/useChangeScreen";
import LoadingIcon from "../components/LoadingIcon/LoadingIcon";
import UserHeader from "../components/UserHeader/UserHeader";
import axiosInstance from "../utils/axios";
import {
  findLocaleLang
} from "../utils/helperFunctions/helperFunction";
import { URLS } from "../utils/constants/urls";
import SplashScreen from "./SplashScreen/SplashScreen";
import Footer from "../components/Footer/Footer";
import { eventNames } from "../utils/constants/events";
import { topBannerAssets } from "../utils/constants/images";
import { Tooltip } from "react-tooltip";

interface RootScreenProps {
  loc: string;
}

const RootScreen: FC<RootScreenProps> = ({ loc }) => {
  const authenticated = useSelector(isAuthenticated);
  const currentScreen = useSelector(CurrentScreen);
  const loading = useSelector(isLoading);
  const Screen = changeScreen(currentScreen);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [UILoaded, setUILoaded] = useState(false);
  const [localeApiData, setLocaleApiData] = useState<any>(null);
  const [changeLanguage, setChangeLanguage] = useState<any>(null);
  const [topBanner, setTopBanner] = useState<any>("");
  const [localeLoaded, setLocaleLoaded] = useState(true);
  const [topBannerLoaded, setTopBannerLoaded] = useState(false);
  const bannerLoaded = useSelector(BannerLoaded);
  const dispatch = useDispatch();
  const user = useSelector(User);
  const locale = useSelector(Locale);

  const getLocalizationsData = async () => {
    try {
      const { data } = await axiosInstance(URLS.localization(loc));
      if (data) {
        setLocaleLoaded(true);
        const findLocale = findLocaleLang(data.fileContent);
        if (findLocale) {
          dispatch(setLocale(findLocale));
          setLocaleApiData(data.fileContent);
          if (authenticated) {
            setUILoaded(true);
            if(user?.isLoggedIn || user?.email){
              dispatch(setCurrentScreen(screenNames.userWelcomeScreen));
            }else{
              dispatch(setCurrentScreen(screenNames.clientSelection));
            }
          } else {
            dispatch(setCurrentScreen(screenNames.welcomeScreen));
          }
          dispatch(setLoc(loc));
          const findTopBanner = topBannerAssets.find(
            (ele) => ele.language === findLocale?.language
          );
          setTopBanner(findTopBanner?.banner);
        }
      }
    } catch (error) {
      setUILoaded(true);
      const currentLang = localStorage.getItem("current-lang");
      if (currentLang) {
        const findTopBanner = topBannerAssets.find(
          (ele) => ele.language === currentLang
        );
        setTopBanner(findTopBanner?.banner);
        dispatch(setLocale({...locale, language: currentLang}));
      }
      if(!authenticated){
        dispatch(setCurrentScreen(screenNames.welcomeScreen));
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (changeLanguage) {
      const findLocale = findLocaleLang(localeApiData, changeLanguage);
      if (findLocale) {
        dispatch(setLocale(findLocale));
      }
    }
  }, [changeLanguage]);

  useEffect(() => {
    getLocalizationsData();
    window.addEventListener("message", (eventData: any) => {
      try {
        if (eventData?.data) {
          const parsedData = JSON.parse(eventData.data);
          if (parsedData.event_code === "receive-event-from-bot") {
            handleEvents(
              parsedData.data,
              (screen: string, data: any) => {
                if (screen === eventNames.language) {
                  setChangeLanguage(data);
                }
              }
            );
          }
        }
      } catch (error) {}
    });
  }, []);

  useEffect(() => {
    if (topBannerLoaded && bannerLoaded && localeLoaded) {
      setUILoaded(true);
    }
  }, [topBannerLoaded, bannerLoaded, localeLoaded]);

  return (
    <Box className="flex">
      {showSplashScreen && !UILoaded && <SplashScreen />}
      <Tooltip
        id="custom-tooltip"
        place="bottom"
        style={{ background: "black", opacity: 1, padding: "5px 10px", zIndex:999 }}
      />
      <Box className="fullvh flex fdc" width={"40%"}>
        {loading && <LoadingIcon />}
        {authenticated && <UserHeader />}
        <Box height={"100%"} sx={{ overflowY: "auto" }}>
          {Screen}
        </Box>
        <Footer />
      </Box>
      <Box className="fullvh flex fdc" width={"60%"} bgcolor={"white"}>
        <img
          onLoad={() => {
            setTopBannerLoaded(true);
          }}
          alt="gsk-top-banner"
          src={topBanner}
          style={{ width: "100%", height: "78px" }}
        />
        <Box width="100%" flexGrow={1} display={"flex"}>
          {UILoaded && (
            <iframe
              onLoad={() => {
                setShowSplashScreen(false);
              }}
              title="bot-iframe"
              id="bot-container-iframe"
              src="https://cloud.yellow.ai/pwa/v2/live/x1666274014901?fullScreen=true"
              style={{
                flex: 1,
                width: "100%",
                border: "none",
                borderRadius: "20px",
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RootScreen;
